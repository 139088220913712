export default {
    alertFull:
        'M12 22c1.1 0 2-.9 2-2h-4a2 2 0 0 0 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z',
    authors:
        'M2 3h20c1.05 0 2 .95 2 2v14c0 1.05-.95 2-2 2H2c-1.05 0-2-.95-2-2V5c0-1.05.95-2 2-2zm12 3v1h8V6h-8zm0 2v1h8V8h-8zm0 2v1h7v-1h-7zm-6 3.91C6 13.91 2 15 2 17v1h12v-1c0-2-4-3.09-6-3.09zM8 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6z',
    backTo: 'M6.8 13l3.6 3.6L9 18l-6-6 6-6 1.4 1.4L6.8 11h14.6v2H6.8z',
    basketball:
        'M2.34 14.63c.6-.22 1.22-.33 1.88-.33 1.34 0 2.51.42 3.51 1.26L4.59 18.7a10.61 10.61 0 0 1-2.25-4.07M15.56 9.8c1.97 1.47 4.1 1.83 6.38 1.08.03.21.06.59.06 1.12 0 1.03-.25 2.18-.72 3.45-.47 1.26-1.05 2.28-1.73 3.05l-6.33-6.31 2.34-2.39m-6.79 6.84c1.06 1.53 1.28 3.2.65 5.02-1.42-.41-2.69-1.05-3.75-1.93l3.1-3.09m3.42-3.42l6.31 6.33c-2.17 1.9-4.72 2.7-7.62 2.39.21-.66.32-1.38.32-2.16 0-.62-.14-1.35-.42-2.18-.28-.83-.61-1.51-.98-2.04l2.39-2.34M8.81 14.5a6.695 6.695 0 0 0-3.23-1.59c-1.22-.23-2.39-.16-3.52.22-.03-.22-.06-.6-.06-1.13 0-1.03.25-2.18.72-3.45.47-1.26 1.05-2.28 1.73-3.05l6.66 6.69-2.3 2.31m6.75-6.77c-1.34-1.65-1.65-3.45-.93-5.39.62.16 1.33.46 2.13.92.79.45 1.44.9 1.94 1.33l-3.14 3.14m6.1 1.65c-.6.21-1.22.32-1.88.32-1.09 0-2.14-.32-3.14-.98l3.09-3.05c.88 1.1 1.52 2.33 1.93 3.71m-9.47 1.73L5.5 4.45c2.17-1.9 4.72-2.7 7.63-2.39-.22.66-.33 1.38-.33 2.16 0 .72.16 1.53.49 2.44.33.9.71 1.62 1.21 2.15l-2.31 2.3z',
    bing: 'M6 3v16l3.72 2L19 15.82v-4.09l-8.23-2.78 1.61 3.89L14.94 14 9.7 16.92V4.27L6 3',
    bold:
        'M13.5 16.5H10v-3h3.5a1.5 1.5 0 0 1 0 3zm-3.5-9h3a1.5 1.5 0 0 1 0 3h-3v-3zm5.6 4.29c.97-.68 1.65-1.79 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.1 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42z',
    book:
        'M18 22a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-6v7L9.5 7.5 7 9V2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12z',
    bookOpen:
        'M21 4.5c-1.11-.35-2.33-.5-3.5-.5-1.95 0-4.05.4-5.5 1.5C10.55 4.4 8.45 4 6.5 4 4.55 4 2.45 4.4 1 5.5v14.65c0 .25.25.5.5.5.1 0 .15-.05.25-.05 1.35-.65 3.3-1.1 4.75-1.1 1.95 0 4.05.4 5.5 1.5 1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.1.05.15.05.25.05.25 0 .5-.25.5-.5V5.5c-.6-.45-1.25-.75-2-1zM21 18c-1.1-.35-2.3-.5-3.5-.5-1.7 0-4.15.65-5.5 1.5V7.5c1.35-.85 3.8-1.5 5.5-1.5 1.2 0 2.4.15 3.5.5V18z',
    brightness:
        'M12,18C11.11,18 10.26,17.8 9.5,17.45C11.56,16.5 13,14.42 13,12C13,9.58 11.56,7.5 9.5,6.55C10.26,6.2 11.11,6 12,6A6,6 0 0,1 18,12A6,6 0 0,1 12,18M20,8.69V4H15.31L12,0.69L8.69,4H4V8.69L0.69,12L4,15.31V20H8.69L12,23.31L15.31,20H20V15.31L23.31,12L20,8.69Z',
    calendar:
        'M19 20H5V9h14v11zM16 2v2H8V2H6v2H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1V2h-2zm1 11h-5v5h5v-5z',
    chart:
        'M16 11.78l4.24-7.33 1.73 1-5.23 9.05-6.51-3.75L5.46 19H22v2H2V3h2v14.54L9.5 8l6.5 3.78z',
    checkboxCheck:
        'M10 17l-5-5 1.41-1.42L10 14.17l7.59-7.59L19 8l-9 9zm9-14H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2z',
    checkboxEmpty:
        'M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm0 2v14H5V5h14z',
    close:
        'M19 6.4L17.6 5 12 10.6 6.4 5 5 6.4l5.6 5.6L5 17.6 6.4 19l5.6-5.6 5.6 5.6 1.4-1.4-5.6-5.6z',
    compare:
        'M19 3h-5v2h5v13l-5-6v9h5a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-9 15H5l5-6m0-9H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h5v2h2V1h-2v2z',
    crop:
        'M17,15 L19,15 L19,7 C19,5.9 18.1,5 17,5 L9,5 L9,7 L17,7 L17,15 Z M7,17 L7,1 L5,1 L5,5 L1,5 L1,7 L5,7 L5,17 C5,18.1 5.9,19 7,19 L17,19 L17,23 L19,23 L19,19 L23,19 L23,17 L7,17 Z',
    comment: 'M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z',
    commentAlt:
        'M9 22a1 1 0 0 1-1-1v-3H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6.1l-3.7 3.71c-.2.19-.45.29-.7.29H9zm1-6v3.08L13.08 16H20V4H4v12h6z',
    counter:
        'M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm0 2v12h7V6H4zm16 12V6h-1.24c.24.54.19 1.07.19 1.13-.07.67-.54 1.37-.71 1.62l-2.33 2.55 3.32-.02.01 1.22-5.2-.03-.04-1s3.05-3.23 3.2-3.52c.14-.28.71-1.95-.7-1.95-1.23.05-1.09 1.3-1.09 1.3l-1.54.01s.01-.66.38-1.31H13v12h2.58l-.01-.86.97-.01s.91-.16.92-1.05c.04-1-.81-1-.96-1-.13 0-1.07.05-1.07.87h-1.52s.04-2.06 2.59-2.06c2.6 0 2.46 2.02 2.46 2.02s.04 1.25-1.11 1.72l.52.37H20zM8.92 16h-1.5v-5.8l-1.8.56V9.53l3.14-1.12h.16V16z',
    delete:
        'M19 4.944h-3.5L14.5 4h-5l-1 .944H5v1.89h14v-1.89zM6 19.111C6 20.154 6.895 21 8 21h8c1.105 0 2-.846 2-1.889V7.778H6V19.11z',
    desktop: 'M20 16H4V6h16v10zm-6 3h-4v-1h4v1zm8 0V4H2v15H0v2h24v-2h-2z',
    devices:
        'M22 17h-4v-7h4v7zm1-9h-6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1zM4 6h18V4H4a2 2 0 0 0-2 2v11H0v3h14v-3H4V6z',
    downArrow: 'M12 17.6l3.6-3.6 1.4 1.4-6 6-6-6L6.4 14l3.6 3.6V3h2v14.6z',
    download: 'M15 4v5.647h4l-7 6.588-7-6.588h4V4h6zM5 20v-1.882h14V20H5z',
    dropCircle:
        'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 12l-4-4h8l-4 4z',
    dropDown: 'M7 10l5 5 5-5z',
    dropLeft: 'M14.5 7.5l-5 5 5 5z',
    dropRight: 'M9.5 17.5l5-5-5-5z',
    dropUp: 'M7 14l5-5 5 5z',
    expandLess: 'M12 8l-6 6 1.4 1.4 4.6-4.6 4.6 4.6L18 14z',
    expandMore: 'M16.6 8L12 12.6 7.4 8 6 9.4l6 6 6-6z',
    expandMoreLeft:
        'M7.83 12L11 15.17l-1.41 1.42L5 12l4.59-4.58L11 8.83 7.83 12zm8-.01L19 15.16l-1.41 1.42L13 11.99l4.59-4.58L19 8.82l-3.17 3.17z',
    expandMoreRight:
        'M16.17 12L13 8.83l1.41-1.42L19 12l-4.59 4.58L13 15.17 16.17 12zm-8 .01L5 8.84l1.41-1.42L11 12.01l-4.59 4.58L5 15.18l3.17-3.17z',
    eye:
        'M12 9.5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 8a5 5 0 1 1 0-10 5 5 0 0 1 0 10zM12 5C7 5 2.73 8.11 1 12.5 2.73 16.89 7 20 12 20s9.27-3.11 11-7.5C21.27 8.11 17 5 12 5z',
    facebook: 'M17 2v4h-2c-.69 0-1 .81-1 1.5V10h3v4h-3v8h-4v-8H7v-4h3V6a4 4 0 0 1 4-4h3z',
    fastForward: 'M3 18l8.5-6L3 6v12zm9-12v12l8.5-6L12 6z',
    fieldEdit:
        'M5,3 C3.89,3 3,3.89 3,5 L3,19 C3,20.1045695 3.8954305,21 5,21 L19,21 C20.1045695,21 21,20.1045695 21,19 L21,12 L19,12 L19,19 L5,19 L5,5 L12,5 L12,3 L5,3 L5,3 Z M17.78,4 C17.61,4 17.43,4.07 17.3,4.2 L16.08,5.41 L18.58,7.91 L19.8,6.7 C20.06,6.44 20.06,6 19.8,5.75 L18.25,4.2 C18.12,4.07 17.95,4 17.78,4 L17.78,4 Z M15.37,6.12 L8,13.5 L8,16 L10.5,16 L17.87,8.62 L15.37,6.12 Z',
    file: 'M13 9V3.5L18.5 9H13zM6 2c-1.11 0-2 .89-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6H6z',
    fileAdd:
        'M13,9 L18.5,9 L13,3.5 L13,9 Z M6,2 L14,2 L20,8 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.89,22 4,21.1 4,20 L4,4 C4,2.89 4.89,2 6,2 Z M11,15 L11,12 L9,12 L9,15 L6,15 L6,17 L9,17 L9,20 L11,20 L11,17 L14,17 L14,15 L11,15 Z',
    fileAlt:
        'M13 9h5.5L13 3.5V9zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2zm5 2H6v16h12v-9h-7V4z',
    fileArticle:
        'M13 9h5.5L13 3.5V9zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2zm9 16v-2H6v2h9zm3-4v-2H6v2h12z',
    fileCompare:
        'M10 18H6v-2h4v2zm0-4H6v-2h4v2zm0-13v1H6c-1.11 0-2 .89-2 2v16a2 2 0 0 0 2 2h4v1h2V1h-2zm10 7v12c0 1.11-.89 2-2 2h-4v-2h4v-9h-4V9h4.5L14 4.5V2l6 6zm-4 6h-2v-2h2v2zm0 4h-2v-2h2v2z',
    fileImage:
        'M13 9h5.5L13 3.5V9zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2zm0 18h12v-8l-4 4-2-2-6 6zM8 9a2 2 0 1 0 0 4 2 2 0 0 0 0-4z',
    filePoll:
        'M13 9h5.5L13 3.5V9zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2zm1 18h2v-6H7v6zm4 0h2v-8h-2v8zm4 0h2v-4h-2v4z',
    fileTree: 'M3 3h6v4H3V3zm12 7h6v4h-6v-4zm0 7h6v4h-6v-4zm-2-4H7v5h6v2H5V9h2v2h6v2z',
    fileTree2:
        'M3 2h4v3H3V2zm14 7h4v3h-4V9zm0 5h4v3h-4v-3zm4 5v3h-4v-3h4zM5 20V7h2v2h8v2H7v3h8v2H7v3h8v2H5v-1z',
    fileVideo:
        'M13 9h5.5L13 3.5V9zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2zm11 17v-6l-3 2.2V13H7v6h7v-2.2l3 2.2z',
    filter:
        'M6,13 L18,13 L18,11 L6,11 L6,13 Z M3,6 L3,8 L21,8 L21,6 L3,6 Z M10,18 L14,18 L14,16 L10,16 L10,18 Z',
    folderDefault:
        'M10 4H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-8l-2-2z',
    folderFav:
        'M20 6h-8l-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zm-2.06 11L15 15.28 12.06 17l.78-3.33-2.59-2.24 3.41-.29L15 8l1.34 3.14 3.41.29-2.59 2.24.78 3.33z',
    folderImage:
        'M5 17l4.5-6 3.5 4.5 2.5-3L19 17H5zM20 6h-8l-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2z',
    folderLocked:
        'M20 6a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.11.89-2 2-2h6l2 2h8zm-1 11v-4h-1v-1a3 3 0 0 0-6 0v1h-1v4h8zm-4-6a1 1 0 0 1 1 1v1h-2v-1a1 1 0 0 1 1-1z',
    folderMove:
        'M14,18 L14,15 L10,15 L10,11 L14,11 L14,8 L19,13 L14,18 Z M20,6 L12,6 L10,4 L4,4 C2.89,4 2,4.89 2,6 L2,18 C2,19.1045695 2.8954305,20 4,20 L20,20 C21.1045695,20 22,19.1045695 22,18 L22,8 C22,6.89 21.1,6 20,6 Z',
    folderOpen:
        'M19 20H4a2 2 0 0 1-2-2V6c0-1.11.89-2 2-2h6l2 2h7a2 2 0 0 1 2 2H4v10l2.14-8h17.07l-2.28 8.5c-.23.87-1.01 1.5-1.93 1.5z',
    folderRecent:
        'M3,3 C1.89,3 1,3.89 1,5 L1,17 C1,18.1045695 1.8954305,19 3,19 L10.26,19 C11.57,20.88 13.71,22 16,22 C19.8659932,22 23,18.8659932 23,15 C23,13.17 22.28,11.42 21,10.11 L21,7 C21,5.89 20.1,5 19,5 L11,5 L9,3 L3,3 L3,3 Z M16,10 C18.7614237,10 21,12.2385763 21,15 C21,17.7614237 18.7614237,20 16,20 C13.2385763,20 11,17.7614237 11,15 C11,12.2385763 13.2385763,10 16,10 L16,10 Z M15,11 L15,16 L18.61,18.16 L19.36,16.94 L16.5,15.25 L16.5,11 L15,11 Z',
    folderVideo:
        'M20 6a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h6l2 2h8zm-2.778 7.875v-1.458c0-.23-.199-.417-.444-.417h-5.334c-.245 0-.444.187-.444.417v4.166c0 .23.199.417.444.417h5.334c.245 0 .444-.187.444-.417v-1.458L19 16.792v-4.584l-1.778 1.667z',
    frame:
        'M15 5h2V3h-2v2zm4 16h2v-2h-2v2zm0-8h2v-2h-2v2zm0-8h2V3h-2v2zm0 12h2v-2h-2v2zm-4 4h2v-2h-2v2zm4-12h2V7h-2v2zM3 21h2v-2H3v2zm0-8h2v-2H3v2zm0-8h2V3H3v2zm0 12h2v-2H3v2zm0-8h2V7H3v2zm4-4h2V3H7v2zm0 16h2v-2H7v2zm4-16h2V3h-2v2zm0 16h2v-2h-2v2z',
    fullScreen: 'M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z',
    fullScreenAlt:
        'M10 21v-2H6.41l4.5-4.5-1.41-1.41-4.5 4.5V14H3v7h7zm4.5-10.09l4.5-4.5V10h2V3h-7v2h3.59l-4.5 4.5 1.41 1.41z',
    fullScreenExit: 'M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z',
    fullScreenExitAlt:
        'M19.41 3l-4.5 4.5V3.91h-2v7h7v-2h-3.59l4.5-4.5L19.41 3zm-15.5 9.91v2H7.5L3 19.41l1.41 1.41 4.5-4.5v3.59h2v-7h-7z',
    github:
        'M12,2A10,10 0 0,0 2,12C2,16.42 4.87,20.17 8.84,21.5C9.34,21.58 9.5,21.27 9.5,21C9.5,20.77 9.5,20.14 9.5,19.31C6.73,19.91 6.14,17.97 6.14,17.97C5.68,16.81 5.03,16.5 5.03,16.5C4.12,15.88 5.1,15.9 5.1,15.9C6.1,15.97 6.63,16.93 6.63,16.93C7.5,18.45 8.97,18 9.54,17.76C9.63,17.11 9.89,16.67 10.17,16.42C7.95,16.17 5.62,15.31 5.62,11.5C5.62,10.39 6,9.5 6.65,8.79C6.55,8.54 6.2,7.5 6.75,6.15C6.75,6.15 7.59,5.88 9.5,7.17C10.29,6.95 11.15,6.84 12,6.84C12.85,6.84 13.71,6.95 14.5,7.17C16.41,5.88 17.25,6.15 17.25,6.15C17.8,7.5 17.45,8.54 17.35,8.79C18,9.5 18.38,10.39 18.38,11.5C18.38,15.32 16.04,16.16 13.81,16.41C14.17,16.72 14.5,17.33 14.5,18.26C14.5,19.6 14.5,20.68 14.5,21C14.5,21.27 14.66,21.59 15.17,21.5C19.14,20.16 22,16.42 22,12A10,10 0 0,0 12,2Z',
    globe:
        'M17.9 17.39c-.26-.8-1.01-1.39-1.9-1.39h-1v-3a1 1 0 0 0-1-1H8v-2h2a1 1 0 0 0 1-1V7h2a2 2 0 0 0 2-2v-.41a7.984 7.984 0 0 1 2.9 12.8zM11 19.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.22.21-1.79L9 15v1a2 2 0 0 0 2 2v1.93zM12 2C6.477 2 2 6.477 2 12A10 10 0 1 0 12 2z',
    goTo: 'M17.6 11L14 7.4 15.4 6l6 6-6 6-1.4-1.4 3.6-3.6H3v-2h14.6z',
    google:
        'M23 11h-2V9h-2v2h-2v2h2v2h2v-2h2v-2zM8 11v2.4h4c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 1.9-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 5.7 9.9 5 8 5c-3.9 0-7 3.1-7 7s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H8z',
    grid: 'M3 11h8V3H3m0 18h8v-8H3m10 8h8v-8h-8m0-10v8h8V3',
    heading: 'M8.665 5v5.79H14.4V5h2.665v13.818H14.4v-5.796H8.665v5.796H6V5z',
    heartFull:
        'M12 7c-.67-1.732-2.547-3-4.5-3C4.957 4 3 5.932 3 8.5c0 3.529 3.793 6.258 9 11.5 5.207-5.242 9-7.971 9-11.5C21 5.932 19.043 4 16.5 4c-1.955 0-3.83 1.268-4.5 3z',
    history:
        'M13.5 8H12v5l4.28 2.54.72-1.21-3.5-2.08V8M13 3a9 9 0 0 0-9 9H1l3.96 4.03L9 12H6a7 7 0 1 1 7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.896 8.896 0 0 0 13 21a9 9 0 0 0 0-18',
    home: 'M10 21v-6h4v6h5v-8h3L12 4 2 13h3v8z',
    homepage:
        'M20 10H4V6h16v4zm0 4h-7v-2h7v2zm0 4h-7v-2h7v2zm-9 0H4v-6h7v6zM2 6v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z',
    icon:
        'M6 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM6 14a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM6 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z',
    imageEdit:
        'M3,17 L3,19 L9,19 L9,17 L3,17 Z M3,5 L3,7 L13,7 L13,5 L3,5 Z M13,21 L13,19 L21,19 L21,17 L13,17 L13,15 L11,15 L11,21 L13,21 Z M7,9 L7,11 L3,11 L3,13 L7,13 L7,15 L9,15 L9,9 L7,9 Z M21,13 L21,11 L11,11 L11,13 L21,13 Z M15,9 L17,9 L17,7 L21,7 L21,5 L17,5 L17,3 L15,3 L15,9 Z',
    imageFullWidth:
        'M19.2 6c.994 0 1.8.768 1.8 1.714v8.572c0 .946-.806 1.714-1.8 1.714H4.8c-.999 0-1.8-.771-1.8-1.714V7.714C3 6.763 3.801 6 4.8 6h14.4zM6 15h12l-3.857-5-3 3.75L9 11.25 6 15zM4 2h16v2H4V2zm0 18h16v2H4v-2z',
    imageLeftAlign:
        'M13 8.429v7.142c0 .79-.582 1.429-1.3 1.429H1.3C.578 17 0 16.357 0 15.571V8.43C0 7.636.579 7 1.3 7h10.4c.718 0 1.3.64 1.3 1.429zM2 15h9l-2.893-3-2.25 2.25-1.607-1.5L2 15zm13-8h9v2h-9V7zm0 8h9v2h-9v-2zm0-4h9v2h-9v-2z',
    imageOff:
        'M2.28 3L1 4.27l2 2V19a2 2 0 0 0 2 2h12.73l2 2L21 21.72 2.28 3zm2.55 0L21 19.17V5a2 2 0 0 0-2-2H4.83zM8.5 13.5l2.5 3 1-1.25L14.73 18H5l3.5-4.5z',
    imageRightAlign:
        'M24 8.429v7.142c0 .79-.582 1.429-1.3 1.429H12.3c-.722 0-1.3-.643-1.3-1.429V8.43C11 7.636 11.579 7 12.3 7h10.4c.718 0 1.3.64 1.3 1.429zM13 15h9l-2.893-3-2.25 2.25-1.607-1.5L13 15zM0 7h9v2H0V7zm0 8h9v2H0v-2zm0-4h9v2H0v-2z',
    importantAnnouncement:
        'M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 12h-2v-2h2v2zm0-4h-2V6h2v4z',
    inbox:
        'M19 15h-4a3 3 0 0 1-3 3 3 3 0 0 1-3-3H5V5h14m0-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2z',
    info:
        'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z',
    instagram:
        'M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2zm-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6zm9.65 1.5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5zM12 7a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6z',
    italic: 'M10 5v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V5z',
    layout:
        'M13,8.42857143 L13,15.5714286 C13,16.3604068 12.4179702,17 11.7,17 L1.3,17 C0.5785,17 0,16.3571429 0,15.5714286 L0,8.42857143 C0,7.63571429 0.5785,7 1.3,7 L11.7,7 C12.4179702,7 13,7.63959321 13,8.42857143 Z M2,15 L11,15 L8.10714286,12 L5.85714286,14.25 L4.25,12.75 L2,15 Z M15,7 L24,7 L24,9 L15,9 L15,7 Z M15,15 L24,15 L24,17 L15,17 L15,15 Z M15,11 L24,11 L24,13 L15,13 L15,11 Z',
    layoutHero:
        'M19.2 2c.994 0 1.8.768 1.8 1.714v8.572c0 .946-.806 1.714-1.8 1.714H4.8c-.999 0-1.8-.771-1.8-1.714V3.714C3 2.763 3.801 2 4.8 2h14.4zM6 11h12l-3.857-5-3 3.75L9 7.25 6 11zm-2 5h16v2H4v-2zm0 4h16v2H4v-2z',
    layoutNonHero:
        'M13 8.429v7.142c0 .79-.582 1.429-1.3 1.429H1.3C.578 17 0 16.357 0 15.571V8.43C0 7.636.579 7 1.3 7h10.4c.718 0 1.3.64 1.3 1.429zM2 15h9l-2.893-3-2.25 2.25-1.607-1.5L2 15zm13-8h9v2h-9V7zm0 8h9v2h-9v-2zm0-4h9v2h-9v-2z',
    leftArrow: 'M5.8 11l3.6-3.6L8 6l-6 6 6 6 1.4-1.4L5.8 13h14.6v-2H5.8z',
    leftChevron: 'M15.4 7.4L14 6l-6 6 6 6 1.4-1.4-4.6-4.6z',
    link:
        'M10.832 13.652c.41.39.41 1.03 0 1.42-.39.39-1.03.39-1.42 0a5.003 5.003 0 0 1 0-7.07l3.54-3.54a5.003 5.003 0 0 1 7.07 0 5.003 5.003 0 0 1 0 7.07l-1.49 1.49c.01-.82-.12-1.64-.4-2.42l.47-.48a2.982 2.982 0 0 0 0-4.24 2.982 2.982 0 0 0-4.24 0l-3.53 3.53a2.982 2.982 0 0 0 0 4.24zm2.82-4.24c.39-.39 1.03-.39 1.42 0a5.003 5.003 0 0 1 0 7.07l-3.54 3.54a5.003 5.003 0 0 1-7.07 0 5.003 5.003 0 0 1 0-7.07l1.49-1.49c-.01.82.12 1.64.4 2.43l-.47.47a2.982 2.982 0 0 0 0 4.24 2.982 2.982 0 0 0 4.24 0l3.53-3.53a2.982 2.982 0 0 0 0-4.24.973.973 0 0 1 0-1.42z',
    list:
        'M7.5 5.5h14v2h-14v-2zm0 8v-2h14v2h-14zM4.5 5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm0 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm3 8.5v-2h14v2h-14zm-3-2.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z',
    live:
        'M4.93 5A9.969 9.969 0 0 0 2 12.07c0 2.76 1.12 5.26 2.93 7.07l1.41-1.41A7.938 7.938 0 0 1 4 12.07c0-2.21.89-4.22 2.34-5.66L4.93 5zm14.14 0l-1.41 1.41A7.955 7.955 0 0 1 20 12.07c0 2.22-.89 4.22-2.34 5.66l1.41 1.41A9.969 9.969 0 0 0 22 12.07c0-2.76-1.12-5.26-2.93-7.07zM7.76 7.83A5.98 5.98 0 0 0 6 12.07c0 1.65.67 3.15 1.76 4.24l1.41-1.41A3.99 3.99 0 0 1 8 12.07c0-1.11.45-2.11 1.17-2.83L7.76 7.83zm8.48 0l-1.41 1.41A3.99 3.99 0 0 1 16 12.07c0 1.11-.45 2.11-1.17 2.83l1.41 1.41A5.98 5.98 0 0 0 18 12.07c0-1.65-.67-3.15-1.76-4.24zM12 10.07a2 2 0 1 0 0 4 2 2 0 0 0 0-4z',
    livePulse:
        'M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-1.667a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666zM12 17a5 5 0 1 1 0-10 5 5 0 0 1 0 10z',
    location:
        'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z',
    lock:
        'M18 9a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V11a2 2 0 0 1 2-2h9V7a3 3 0 0 0-6 0H7a5 5 0 1 1 10 0v2h1zm-6 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4z',
    locked:
        'M12 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm6-9a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V11a2 2 0 0 1 2-2h1V7a5 5 0 1 1 10 0v2h1zm-6-5a3 3 0 0 0-3 3v2h6V7a3 3 0 0 0-3-3z',
    logo: '9Press-logo',
    mailFull:
        'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z',
    mailOutline:
        'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z',
    media:
        'M21 19V7h1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-1h16zM17 6H3v9h14V6zm2 0v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v1z',
    mediaVideo:
        'M21 19V7h1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-1h16zM17 6H3v9h14V6zm2 0v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v1zM8 8l3.929 2.5L8 13V8z',
    menu: 'M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z',
    messenger:
        'M12 2C6.5 2 2 6.14 2 11.25c0 2.88 1.42 5.45 3.65 7.15l.06 3.6 3.45-1.88-.03-.01c.91.25 1.87.39 2.87.39 5.5 0 10-4.14 10-9.25S17.5 2 12 2zm1.03 12.41l-2.49-2.63-5.04 2.63 5.38-5.63 2.58 2.47 4.85-2.47-5.28 5.63z',
    microphone:
        'M12,2A3,3 0 0,1 15,5V11A3,3 0 0,1 12,14A3,3 0 0,1 9,11V5A3,3 0 0,1 12,2M19,11C19,14.53 16.39,17.44 13,17.93V21H11V17.93C7.61,17.44 5,14.53 5,11H7A5,5 0 0,0 12,16A5,5 0 0,0 17,11H19Z',
    minus: 'M5 11v2h14v-2z',
    mobile:
        'M17 19H7V5h10v14zm0-18H7c-1.11 0-2 .89-2 2v18a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2z',
    money:
        'M7 15h2c0 1.08 1.37 2 3 2s3-.92 3-2c0-1.1-1.04-1.5-3.24-2.03C9.64 12.44 7 11.78 7 9c0-1.79 1.47-3.31 3.5-3.82V3h3v2.18C15.53 5.69 17 7.21 17 9h-2c0-1.08-1.37-2-3-2s-3 .92-3 2c0 1.1 1.04 1.5 3.24 2.03C14.36 11.56 17 12.22 17 15c0 1.79-1.47 3.31-3.5 3.82V21h-3v-2.18C8.47 18.31 7 16.79 7 15z',
    moneyAlt:
        'M12 3l10 9h-3v8H5v-8H2l10-9zM9.22 8.93c-.47.47-.72 1.1-.72 1.82 0 1.68 2.04 2.32 3.26 2.71 1.5.47 1.71.75 1.74.79 0 .75-1.35.75-1.5.75-.63 0-.97-.12-1.14-.22-.19-.11-.36-.28-.36-.78h-2c0 1.43.74 2.16 1.35 2.5.33.2.72.34 1.15.42V18h2v-1.09c1.53-.3 2.5-1.29 2.5-2.66 0-1.58-1.62-2.22-3.14-2.7-1.56-.49-1.83-.78-1.86-.8 0-.25.07-.34.14-.41.21-.21.72-.34 1.36-.34.68 0 1.5.13 1.5.75h2c0-1.41-.94-2.38-2.5-2.66V7h-2v1.08c-.74.13-1.35.42-1.78.85z',
    moon:
        'M17.75,4.09L15.22,6.03L16.13,9.09L13.5,7.28L10.87,9.09L11.78,6.03L9.25,4.09L12.44,4L13.5,1L14.56,4L17.75,4.09M21.25,11L19.61,12.25L20.2,14.23L18.5,13.06L16.8,14.23L17.39,12.25L15.75,11L17.81,10.95L18.5,9L19.19,10.95L21.25,11M18.97,15.95C19.8,15.87 20.69,17.05 20.16,17.8C19.84,18.25 19.5,18.67 19.08,19.07C15.17,23 8.84,23 4.94,19.07C1.03,15.17 1.03,8.83 4.94,4.93C5.34,4.53 5.76,4.17 6.21,3.85C6.96,3.32 8.14,4.21 8.06,5.04C7.79,7.9 8.75,10.87 10.95,13.06C13.14,15.26 16.1,16.22 18.97,15.95M17.33,17.97C14.5,17.81 11.7,16.64 9.53,14.5C7.36,12.31 6.2,9.5 6.04,6.68C3.23,9.82 3.34,14.64 6.35,17.66C9.37,20.67 14.19,20.78 17.33,17.97Z',
    moreHorizontal:
        'M4 14a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm8 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm8 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z',
    moreVertical:
        'M10 4a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0z',
    networkMenu:
        'M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z',
    nineDots:
        'M6 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM6 14a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM6 20a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z',
    numberList:
        'M8,13 L22,13 L22,11 L8,11 L8,13 Z M8,19 L22,19 L22,17 L8,17 L8,19 Z M8,7 L22,7 L22,5 L8,5 L8,7 Z M3,11 L4.8,11 L3,13.1 L3,14 L6,14 L6,13 L4.2,13 L6,10.9 L6,10 L3,10 L3,11 Z M4,8 L5,8 L5,4 L3,4 L3,5 L4,5 L4,8 Z M3,17 L5,17 L5,17.5 L4,17.5 L4,18.5 L5,18.5 L5,19 L3,19 L3,20 L6,20 L6,16 L3,16 L3,17 Z',
    omega:
        'M19.15,20 L13.39,20 L13.39,17.87 C15.5,16.25 16.59,14.24 16.59,11.84 C16.59,10.34 16.16,9.16 15.32,8.29 C14.47,7.42 13.37,7 12.03,7 C10.68,7 9.57,7.42 8.71,8.3 C7.84,9.17 7.41,10.37 7.41,11.88 C7.41,14.26 8.5,16.26 10.61,17.87 L10.61,20 L4.85,20 L4.85,17.87 L8.41,17.87 C6.04,16.32 4.85,14.23 4.85,11.6 C4.85,9.5 5.5,7.86 6.81,6.66 C8.12,5.45 9.84,4.85 11.97,4.85 C14.15,4.85 15.89,5.45 17.19,6.64 C18.5,7.83 19.15,9.5 19.15,11.58 C19.15,14.21 17.95,16.31 15.55,17.87 L19.15,17.87 L19.15,20 Z',
    openNew:
        'M14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7zm5 16H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7h-2v7z',
    pause: 'M6 19h4V5H6v14zm8-14v14h4V5h-4z',
    pdf:
        'M12,10.5H13V13.5H12V10.5M7,11.5H8V10.5H7V11.5M20,6V18A2,2 0 0,1 18,20H6A2,2 0 0,1 4,18V6A2,2 0 0,1 6,4H18A2,2 0 0,1 20,6M9.5,10.5A1.5,1.5 0 0,0 8,9H5.5V15H7V13H8A1.5,1.5 0 0,0 9.5,11.5V10.5M14.5,10.5A1.5,1.5 0 0,0 13,9H10.5V15H13A1.5,1.5 0 0,0 14.5,13.5V10.5M18.5,9H15.5V15H17V13H18.5V11.5H17V10.5H18.5V9Z',
    pen:
        'M20.71 8.043c-.34.34-.67.67-.68 1-.03.32.31.65.63.96.48.5.95.95.93 1.44-.02.49-.53 1-1.04 1.5l-4.13 4.14-1.42-1.42 4.25-4.24-.96-.96-1.42 1.41-3.75-3.75 3.84-3.83c.39-.39 1.04-.39 1.41 0l2.34 2.34c.39.37.39 1.02 0 1.41zM3 18.253l9.56-9.57 3.75 3.75-9.56 9.57H3v-3.75z',
    pencil:
        'M20.71 7.043c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83 3.75 3.75 1.84-1.83zM3 17.253v3.75h3.75l11.06-11.07-3.75-3.75L3 17.253z',
    photo:
        'M9 3L7.17 5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2h-3.17L15 3H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-1.8a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4z',
    pinterest:
        'M13.25 17.25c-1 0-1.96-.43-2.65-1.15l-1.19 4-.08.26-.04-.02c-.25.41-.68.66-1.17.66-.75 0-1.37-.62-1.37-1.38 0-.06.01-.12.02-.18l-.02-.01.06-.22 2.31-6.95s-.25-.76-.25-1.84c0-2.15 1.16-2.8 2.08-2.8.93 0 1.78.33 1.78 1.64 0 1.68-1.12 2.54-1.12 3.74 0 .94.76 1.69 1.68 1.69 2.92 0 3.96-2.19 3.96-4.25 0-2.73-2.36-4.94-5.25-4.94-2.9 0-5.25 2.21-5.25 4.94 0 .84.25 1.68.68 2.41.11.2.17.42.17.65 0 .69-.56 1.25-1.25 1.25-.44 0-.85-.25-1.08-.62a7.226 7.226 0 0 1-1.02-3.69C4.25 6.33 7.73 3 12 3s7.75 3.33 7.75 7.44c0 3.28-2.04 6.81-6.5 6.81z',
    play: 'M8 5v14l11-7z',
    playCircle:
        'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z',
    playCircleAlt:
        'M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z',
    plus: 'M11 5v6H5v2h6v6h2v-6h6v-2h-6V5z',
    plusCircle:
        'M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-18C6.477 2 2 6.477 2 12A10 10 0 1 0 12 2zm1 5h-2v4H7v2h4v4h2v-4h4v-2h-4V7z',
    podcast:
        'M11.667 14c1.84 0 3.333.897 3.333 2.222 0 1.334-.178 2.68-.533 3.873-.207.693-.628 1.554-.628 1.554-.095.193-.372.351-.617.351h-3.11c-.245 0-.523-.158-.617-.351 0 0-.422-.86-.629-1.554-.355-1.192-.533-2.54-.533-3.873 0-1.325 1.493-2.222 3.334-2.222zm0-1.333a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-10.667c4.779 0 8.666 3.888 8.666 8.667a8.667 8.667 0 0 1-4.102 7.363c.063-.56.097-1.133.1-1.71A7.32 7.32 0 0 0 19 10.668c0-4.044-3.29-7.334-7.333-7.334-4.044 0-7.334 3.29-7.334 7.334a7.32 7.32 0 0 0 2.669 5.654c.003.576.038 1.148.1 1.709A8.667 8.667 0 0 1 3 10.667C3 5.887 6.888 2 11.667 2zm-2.92 11.391a3.834 3.834 0 0 0-1.028.858 5.312 5.312 0 0 1-1.386-3.582 5.34 5.34 0 0 1 5.334-5.334A5.34 5.34 0 0 1 17 10.667a5.312 5.312 0 0 1-1.386 3.582 3.834 3.834 0 0 0-1.027-.858 3.98 3.98 0 0 0 1.08-2.724c0-2.206-1.795-4-4-4-2.206 0-4 1.794-4 4a3.98 3.98 0 0 0 1.08 2.724z',
    podcastAlt:
        'M17.5 18.75V22h-10v-3.25c0-1.38 2.24-2.5 5-2.5s5 1.12 5 2.5zM12.5 6a6.5 6.5 0 0 1 6.5 6.5c0 1.25-.35 2.42-.96 3.41l-1.54-1.37c.32-.61.5-1.31.5-2.04C17 10 15 8 12.5 8S8 10 8 12.5c0 .73.18 1.43.5 2.04l-1.54 1.37C6.35 14.92 6 13.75 6 12.5A6.5 6.5 0 0 1 12.5 6zm0-4C18.299 2 23 6.701 23 12.5c0 2.28-.73 4.39-1.96 6.11l-1.5-1.35a8.5 8.5 0 1 0-14.08 0l-1.5 1.35A10.473 10.473 0 0 1 2 12.5C2 6.701 6.701 2 12.5 2zm0 8a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5z',
    poll: 'M3 22V8h4v14H3zm7 0V2h4v20h-4zm7 0v-8h4v8h-4z',
    puzzle:
        'M20.5 11H19V7a2 2 0 0 0-2-2h-4V3.5a2.5 2.5 0 1 0-5 0V5H4a2 2 0 0 0-2 2v3.8h1.5c1.5 0 2.7 1.2 2.7 2.7 0 1.5-1.2 2.7-2.7 2.7H2V20a2 2 0 0 0 2 2h3.8v-1.5c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7V22H17a2 2 0 0 0 2-2v-4h1.5a2.5 2.5 0 1 0 0-5z',
    quiz:
        'M4 2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6.1l-3.7 3.71c-.2.19-.45.29-.7.29H9a1 1 0 0 1-1-1v-3H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm8.19 3.5c-.89 0-1.6.18-2.14.54-.55.36-.83.96-.78 1.65h1.97c0-.28.1-.49.26-.63.2-.14.42-.21.69-.21.31 0 .58.08.76.26.18.17.27.39.27.69 0 .28-.08.53-.22.74-.17.22-.38.4-.64.54-.52.32-.86.6-1.07.84-.19.24-.29.58-.29 1.08h2c0-.28.05-.5.14-.68.09-.17.26-.32.52-.47.46-.21.84-.49 1.13-.85.29-.37.44-.76.44-1.2 0-.7-.27-1.26-.81-1.68-.54-.41-1.29-.62-2.23-.62zM11 12v2h2v-2h-2z',
    quote: 'M14 17h3l2-4V7h-6v6h3l-2 4zm-8 0h3l2-4V7H5v6h3l-2 4z',
    refresh:
        'm13,3a9,9 0 0 0 -9,9l-3,0l3.96,4.03l4.04,-4.03l-3,0a7,7 0 1 1 7,7c-1.93,0 -3.68,-0.79 -4.94,-2.06l-1.42,1.42a8.896,8.896 0 0 0 6.36,2.64a9,9 0 0 0 0,-18',
    reorder:
        'M14.83,13.41 L13.42,14.82 L16.55,17.95 L14.5,20 L20,20 L20,14.5 L17.96,16.54 L14.83,13.41 L14.83,13.41 Z M14.5,4 L16.54,6.04 L4,18.59 L5.41,20 L17.96,7.46 L20,9.5 L20,4 L14.5,4 Z M10.59,9.17 L5.41,4 L4,5.41 L9.17,10.58 L10.59,9.17 Z',
    rewind: 'M11.5 18V6L3 12l8.5 6zm.5-6l8.5 6V6L12 12z',
    rightArrow: 'M16.6 11L13 7.4 14.4 6l6 6-6 6-1.4-1.4 3.6-3.6H2v-2h14.6z',
    rightChevron: 'M8 7.4L9.4 6l6 6-6 6L8 16.6l4.6-4.6z',
    save:
        'M15 9H5V5h10v4zm-3 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm5-16H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V7l-4-4z',
    schedule:
        'M14 13h1.5v2.82l2.44 1.41-.75 1.3L14 16.69V13zm4-5H4v11h4.67c-.43-.91-.67-1.93-.67-3a7 7 0 0 1 7-7c1.07 0 2.09.24 3 .67V8zM4 21a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2h1V1h2v2h8V1h2v2h1a2 2 0 0 1 2 2v6.1c1.24 1.26 2 2.99 2 4.9a7 7 0 0 1-7 7c-1.91 0-3.64-.76-4.9-2H4zm11-9.85a4.85 4.85 0 1 0 0 9.7 4.85 4.85 0 0 0 0-9.7z',
    search:
        'M15.502 14h-.794l-.276-.274A6.47 6.47 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16a6.47 6.47 0 0 0 4.225-1.566l.276.274v.792l4.998 4.991L20.49 19l-4.988-5zM9.5 14a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9z',
    settings:
        'M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65A.488.488 0 0 0 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z',
    shareAlt:
        'M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7 0-.24-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81a3 3 0 1 0-3-3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9a3 3 0 0 0 0 6c.79 0 1.5-.31 2.04-.81l7.12 4.15c-.05.21-.08.43-.08.66 0 1.61 1.31 2.91 2.92 2.91a2.915 2.915 0 1 0 0-5.83z',
    shares: 'M21 11l-7-7v4C7 9 4 14 3 19c2.5-3.5 6-5.1 11-5.1V18l7-7z',
    sitemap:
        'M9 2v6h2v3H5c-1.11 0-2 .89-2 2v3H1v6h6v-6H5v-3h6v3H9v6h6v-6h-2v-3h6v3h-2v6h6v-6h-2v-3c0-1.11-.89-2-2-2h-6V8h2V2H9z',
    skipBack: 'M6,18 L6,6 L8,6 L8,18 L6,18 Z M9.5,12 L18,6 L18,18 L9.5,12 Z',
    skipForward: 'M16,18 L18,18 L18,6 L16,6 L16,18 Z M6,18 L14.5,12 L6,6 L6,18 Z',
    star:
        'M12 18.27L18.18 22l-1.64-7.03L22 10.24l-7.19-.62L12 3 9.19 9.62 2 10.24l5.45 4.73L5.82 22z',
    starOutline:
        'M12,16.39 L8.24,18.66 L9.23,14.38 L5.91,11.5 L10.29,11.13 L12,7.09 L13.71,11.13 L18.09,11.5 L14.77,14.38 L15.76,18.66 L12,16.39 Z M22,10.24 L14.81,9.63 L12,3 L9.19,9.63 L2,10.24 L7.45,14.97 L5.82,22 L12,18.27 L18.18,22 L16.54,14.97 L22,10.24 Z',
    stop: 'M6 6h12v12H6z',
    storyblock:
        'M13 12h7v1.5h-7m0-4h7V11h-7m0 3.5h7V16h-7m8-12H3a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2m0 15h-9V6h9',
    storyblockAlt: 'M2 7h8v10H2V7zm10 0h10v2H12V7zm0 4h10v2H12v-2zm0 4h4v2h-4v-2z',
    sun:
        'M3.55,18.54L4.96,19.95L6.76,18.16L5.34,16.74M11,22.45C11.32,22.45 13,22.45 13,22.45V19.5H11M12,5.5A6,6 0 0,0 6,11.5A6,6 0 0,0 12,17.5A6,6 0 0,0 18,11.5C18,8.18 15.31,5.5 12,5.5M20,12.5H23V10.5H20M17.24,18.16L19.04,19.95L20.45,18.54L18.66,16.74M20.45,4.46L19.04,3.05L17.24,4.84L18.66,6.26M13,0.55H11V3.5H13M4,10.5H1V12.5H4M6.76,4.84L4.96,3.05L3.55,4.46L5.34,6.26L6.76,4.84Z',
    tablet:
        'M18.347 19H4.653V3h13.694v16zm-4.958 3H9.61v-1h3.778v1zm3.778-22H5.833C4.27 0 3 1.343 3 3v18c0 1.657 1.269 3 2.833 3h11.334C18.73 24 20 22.657 20 21V3c0-1.657-1.269-3-2.833-3z',
    tag:
        'M5.5 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm15.91 4.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.11 0-2 .89-2 2v7c0 .55.22 1.05.59 1.41l8.99 9c.37.36.87.59 1.42.59.55 0 1.05-.23 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.56-.23-1.06-.59-1.42z',
    text: 'M3 13h3v7h3v-7h3v-3H3v3zm6-8v3h5v12h3V8h5V5H9z',
    textEdit:
        'M2,5 L2,7 L18,7 L18,5 L2,5 Z M2,9 L2,11 L14,11 L14,9 L2,9 Z M20.04,9.13 C19.9,9.13 19.76,9.19 19.65,9.3 L18.65,10.3 L20.7,12.35 L21.7,11.35 C21.92,11.14 21.92,10.79 21.7,10.58 L20.42,9.3 C20.31,9.19 20.18,9.13 20.04,9.13 Z M18.07,10.88 L12,16.94 L12,19 L14.06,19 L20.12,12.93 L18.07,10.88 Z M2,13 L2,15 L10,15 L10,13 L2,13 Z',
    tick: 'M8.6 15.6l-4.2-4.2L3 12.8l5.6 5.6 12-12L19.2 5z',
    tickAll:
        'M0 12.83l5.59 5.59L7 17l-5.58-5.58L0 12.83zM21.83 5L11.25 15.59l-4.16-4.17-1.43 1.41 5.59 5.59 12-12L21.83 5zm-4.24 1.42L16.18 5l-6.35 6.35 1.42 1.41 6.34-6.34z',
    time:
        'M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-18a10 10 0 0 1 10 10c0 5.523-4.477 10-10 10-5.53 0-10-4.5-10-10C2 6.477 6.477 2 12 2zm.5 5v5.25l4.5 2.67-.75 1.23L11 13V7h1.5z',
    timetable:
        'M14 12h1.5v2.82l2.44 1.41-.75 1.3L14 15.69V12zM4 2h14a2 2 0 0 1 2 2v6.1c1.24 1.26 2 2.99 2 4.9a7 7 0 0 1-7 7c-1.91 0-3.64-.76-4.9-2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 13v3h4.67c-.43-.91-.67-1.93-.67-3H4zm0-7h6V5H4v3zm14 0V5h-6v3h6zM4 13h4.29c.34-1.15.97-2.18 1.81-3H4v3zm11-2.85a4.85 4.85 0 1 0 0 9.7 4.85 4.85 0 0 0 0-9.7z',
    top: 'M8 11h3v10h2V11h3l-4-4-4 4zM4 3v2h16V3H4z',
    trash:
        'M19,4.94444444 L15.5,4.94444444 L14.5,4 L9.5,4 L8.5,4.94444444 L5,4.94444444 L5,6.83333333 L19,6.83333333 L19,4.94444444 Z M6,19.1111111 C6,20.1543156 6.8954305,21 8,21 L16,21 C17.1045695,21 18,20.1543156 18,19.1111111 L18,7.77777778 L6,7.77777778 L6,19.1111111 Z',
    trending: 'M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z',
    tumblr:
        'M16.48 17.378c-.335.16-.975.298-1.452.31-1.442.039-1.721-1.012-1.733-1.774v-5.603h3.615V7.585h-3.602V3h-2.636c-.044 0-.12.038-.13.134C10.387 4.538 9.73 7.001 7 7.986v2.325h1.822v5.88c0 2.014 1.485 4.875 5.406 4.808 1.324-.023 2.793-.577 3.117-1.055l-.865-2.566',
    twitter:
        'M22.92 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.83 4.5 17.72 4 16.46 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98-3.56-.18-6.73-1.89-8.84-4.48-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.9 20.29 6.16 21 8.58 21c7.88 0 12.21-6.54 12.21-12.21 0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z',
    upArrow: 'M11 6.8l-3.6 3.6L6 9l6-6 6 6-1.4 1.4L13 6.8v14.6h-2V6.8z',
    upload: 'M9 16.235v-5.647H5L12 4l7 6.588h-4v5.647H9zM5 20v-1.882h14V20H5z',
    user:
        'M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z',
    userCheck:
        'M9 5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7zm0 8.75c3.87 0 7 1.57 7 3.5V19H2v-1.75c0-1.93 3.13-3.5 7-3.5zm8-1.09l-2.75-3 1.16-1.16L17 10.09l3.59-3.59 1.16 1.41L17 12.66z',
    users:
        'M16 13c-.29 0-.62 0-.97.05C16.19 13.89 17 15 17 16.5V19h6v-2.5c0-2.33-4.67-3.5-7-3.5zm-8 0c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm0-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm8 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6z',
    video:
        'M17 10.5V7a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-3.5l4 4v-11l-4 4z',
    volumeDown:
        'M18.5 12A4.5 4.5 0 0 0 16 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02zM5 9v6h4l5 5V4L9 9H5z',
    volumeMute: 'M8 9v6h4l5 5V4l-5 5z',
    volumeMuteAlt:
        'M3 9h4l5-5v16l-5-5H3V9zm13.59 3L14 9.41 15.41 8 18 10.59 20.59 8 22 9.41 19.41 12 22 14.59 20.59 16 18 13.41 15.41 16 14 14.59 16.59 12z',
    volumeOff:
        'M16.5 12A4.5 4.5 0 0 0 14 7.97v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51A8.796 8.796 0 0 0 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06a8.99 8.99 0 0 0 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z',
    volumeUp:
        'M3 8.77v6h4l5 5v-16l-5 5H3zm13.5 3A4.5 4.5 0 0 0 14 7.74v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77 0-4.28-2.99-7.86-7-8.77z',
    wand:
        'M8.5,4.6 L6,6 L7.4,3.5 L6,1 L8.5,2.4 L11,1 L9.6,3.5 L11,6 L8.5,4.6 Z M20.5,14.4 L23,13 L21.6,15.5 L23,18 L20.5,16.6 L18,18 L19.4,15.5 L18,13 L20.5,14.4 Z M23,1 L21.6,3.5 L23,6 L20.5,4.6 L18,6 L19.4,3.5 L18,1 L20.5,2.4 L23,1 Z M14.34,11.78 L16.78,9.34 L14.66,7.22 L12.22,9.66 L14.34,11.78 Z M15.37,6.29 L17.71,8.63 C18.1,9 18.1,9.65 17.71,10.04 L6.04,21.71 C5.65,22.1 5,22.1 4.63,21.71 L2.29,19.37 C1.9,19 1.9,18.35 2.29,17.96 L13.96,6.29 C14.35,5.9 15,5.9 15.37,6.29 Z',
    warning: 'M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z',
    website:
        'M16.36,14 C16.44,13.34 16.5,12.68 16.5,12 C16.5,11.32 16.44,10.66 16.36,10 L19.74,10 C19.9,10.64 20,11.31 20,12 C20,12.69 19.9,13.36 19.74,14 L16.36,14 Z M14.59,19.56 C15.19,18.45 15.65,17.25 15.97,16 L18.92,16 C17.96,17.65 16.43,18.93 14.59,19.56 Z M14.34,14 L9.66,14 C9.56,13.34 9.5,12.68 9.5,12 C9.5,11.32 9.56,10.65 9.66,10 L14.34,10 C14.43,10.65 14.5,11.32 14.5,12 C14.5,12.68 14.43,13.34 14.34,14 Z M12,19.96 C11.17,18.76 10.5,17.43 10.09,16 L13.91,16 C13.5,17.43 12.83,18.76 12,19.96 Z M8,8 L5.08,8 C6.03,6.34 7.57,5.06 9.4,4.44 C8.8,5.55 8.35,6.75 8,8 Z M5.08,16 L8,16 C8.35,17.25 8.8,18.45 9.4,19.56 C7.57,18.93 6.03,17.65 5.08,16 Z M4.26,14 C4.1,13.36 4,12.69 4,12 C4,11.31 4.1,10.64 4.26,10 L7.64,10 C7.56,10.66 7.5,11.32 7.5,12 C7.5,12.68 7.56,13.34 7.64,14 L4.26,14 Z M12,4.03 C12.83,5.23 13.5,6.57 13.91,8 L10.09,8 C10.5,6.57 11.17,5.23 12,4.03 Z M18.92,8 L15.97,8 C15.65,6.75 15.19,5.55 14.59,4.44 C16.43,5.07 17.96,6.34 18.92,8 Z M12,2 C6.47,2 2,6.5 2,12 C2,14.6521649 3.0535684,17.195704 4.92893219,19.0710678 C6.80429597,20.9464316 9.3478351,22 12,22 C17.5228475,22 22,17.5228475 22,12 C22,9.3478351 20.9464316,6.80429597 19.0710678,4.92893219 C17.195704,3.0535684 14.6521649,2 12,2 Z',
    whatsApp:
        'M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88zM12 2a10 10 0 0 1 10 10c0 5.523-4.477 10-10 10-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12C2 6.477 6.477 2 12 2zm0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 1 0 0-16z',
    window:
        'M19 4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h14m0 14V8H5v10h14z',
};
